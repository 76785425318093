














import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { FilterInput } from '@/shared/services/filter/filter.service';

import { DivisionModule } from '@/store/index';

@Component({
  components: {
    Fragment
  }
})
export default class DIVFilter extends Vue {
  @Prop() filter: FilterInput<string>;

  DivisionModule = DivisionModule;

  divOptions = [
    { value: null, text: 'All' },
    { value: 'HOU', text: 'HOU' },
    { value: 'LA', text: 'LA' },
    { value: 'PHX', text: 'PHX' },
    { value: 'SAV', text: 'SAV' },
    { value: 'SD', text: 'SD' }
  ];

  constructor() {
    super();
  }

  created() {
    // this.divOptions.push(
    //   ...DivisionModule.divisionsList.map(i => ({ value: i, text: i }))
    // );
  }
}
